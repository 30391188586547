import { catchError, Observable } from "rxjs";
import { CameraInfo, parseVesselLocationEvent, parseVesselMetadataEvent, PilotageInfo, PointOfInterestInfo, StationFenceInfo, VesselLocationEvent, VesselMetadataEvent } from "./types";
import { map } from "rxjs/operators";
import { inject, Provider, Type } from "@angular/core";
import { RxStompService } from "common";
import { Instant } from "@js-joda/core";

export abstract class MapDataProvider {

    abstract readonly pilotages$: Observable<PilotageInfo[]>;
    abstract readonly pointsOfInterest$: Observable<PointOfInterestInfo[]>;
    abstract readonly cameras$: Observable<CameraInfo[]>;
    protected readonly rxStompService = inject(RxStompService);

    abstract getCurrentAisData(): Observable<CurrentAisData>;

    abstract findStationFences(): Observable<StationFenceInfo[]>;

    abstract cameraUrl(cameraId: string, lastUpdated: Instant | null): string;

    subscribeToLocationUpdates(data: CurrentAisData): Observable<VesselLocationEvent[]> {
        return this.rxStompService.watch("/topic/" + data.locationUpdatesTopic).pipe(
            map(msg => JSON.parse(msg.body).events.map(parseVesselLocationEvent)),
            catchError(e => {
                console.error("failed to fetch location updates", e);
                return [];
            }));
    }

    subscribeToMetadataUpdates(data: CurrentAisData): Observable<VesselMetadataEvent[]> {
        return this.rxStompService.watch("/topic/" + data.metadataUpdatesTopic).pipe(
            map(msg => JSON.parse(msg.body).events.map(parseVesselMetadataEvent)),
            catchError(e => {
                console.error("failed to fetch metadata events", e);
                return [];
            }));
    }
}

export function provideMapData(providerClass: Type<MapDataProvider>): Provider[] {
    return [
        {provide: MapDataProvider, useClass: providerClass}
    ];
}

export interface CurrentAisData {
    vessels: VesselLocationData[];
    metadataUpdatesTopic: string,
    locationUpdatesTopic: string,
}

export interface VesselLocationData {
    location: VesselLocationEvent | null;
    metadata: VesselMetadataEvent | null;
}
