<h4 class="text-base font-bold m-0">{{ title }}</h4>

@if (metadata != null) {
    <div>{{ mmsi }}</div>
    <div>{{ metadata.destination }}</div>
}

@if (pilotage != null) {
    <div>{{ mmsi }}</div>
    <div>
        <div>{{ pilotage.state | pilotageStateName }}</div>
        <div>{{ pilotage.startCode }}-{{ pilotage.endCode }}</div>
        <div>{{ pilotage.startTime | helsinkiDate:'dd.MM. HH:mm' }}&mdash;{{ pilotage.endTime | helsinkiDate: 'HH:mm' }}</div>
    </div>
}

<div>
    <a href="" (click)="startDistanceMeasurement($event)">Measure distance...</a>
</div>

@if (expirationInfo) {
    <div class="mt-4 text-xs italic whitespace-nowrap">
        {{ expirationInfo }}
    </div>
}
