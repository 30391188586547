import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { LngLat, Map as MapBoxMap, MapMouseEvent } from "mapbox-gl";
import { Feature, LineString, Point } from "geojson";
import { createLineString } from "../geojson-mappings";
import { observeMapBoxEvent } from "../mapbox/mapbox-rx";
import { VesselData } from "../vessel-data";
import { DistanceOverlayComponent } from "./distance-overlay.component";
import { ApplicationRef, Injector, ViewContainerRef } from "@angular/core";
import { MapboxAngularControl } from "../mapbox/mapbox-angular-control";
import { MapboxAngularPopup } from "../mapbox/mapbox-angular-popup";
import { DistancePopupComponent } from "./distance-popup.component";
import { currentLocalDateTimeMinutes, formatMinutes, formatTime, Minutes } from "common";

export class DistanceMeasurer {

    private readonly points: LngLat[] = [];
    private popup?: MapboxAngularPopup<DistancePopupComponent>;
    private control?: MapboxAngularControl<DistanceOverlayComponent>;
    private stopped = false;
    private disposed = false;
    private readonly disposed$ = new Subject<void>();
    private readonly stopMeasuring$ = new Subject<void>();
    private highlighted = false;

    constructor(private readonly delegate: DistanceMeasurerDelegate,
                private readonly mapBox: MapBoxMap,
                private readonly vessel: VesselData | undefined,
                private readonly startPoint: () => LngLat,
                private readonly interestingLayers: string[],
                private readonly applicationRef: ApplicationRef,
                private readonly viewContainerRef: ViewContainerRef,
                private readonly injector: Injector) {

        this.popup = DistancePopupComponent.create(mapBox, applicationRef, viewContainerRef, injector, vessel, this);

        this.popup.onClose(() => {
            if (!this.stopped)
                this.dispose();
        });

        this.points.push(startPoint());

        observeMapBoxEvent(this.mapBox, "click").pipe(takeUntil(this.stopMeasuring$)).subscribe(e => {
            e.preventDefault();
            this.points.push(this.resolvePoint(e));
            this.redraw();
        });
    }

    /**
     * If this measurer is for a vessel, returns the mmsi of the vessel
     */
    get vesselMmsi(): number | undefined {
        return this.vessel?.mmsi;
    }

    private redraw(): void {
        this.delegate.updateDistanceLayer();

        const popup = this.popup;
        if (popup != null && this.points.length > 1) {
            popup.setLocation(this.points[this.points.length - 1]);
            if (!popup.isOpen())
                popup.addBackToMap();
            popup.componentInstance.updateData(this.points);
        }

        this.control?.componentInstance.updateData(this.points);
    }

    createRenderFeature(): Feature<LineString> {
        return createLineString(this.points, {width: this.highlighted ? 2 : 1});
    }

    recalculateStart(): void {
        this.points[0] = this.startPoint();
        this.redraw();
    }

    private resolvePoint(event: MapMouseEvent): LngLat {
        const features = this.mapBox.queryRenderedFeatures(event.point, {layers: this.interestingLayers});
        if (features.length > 0) {
            const pt = features[0].geometry as Point;
            return new LngLat(pt.coordinates[0], pt.coordinates[1]);
        } else {
            return event.lngLat;
        }
    }

    isStopped(): boolean {
        return this.stopped;
    }

    stopMeasuring(): void {
        if (this.stopped) return;
        this.stopped = true;

        this.stopMeasuring$.next();
        this.stopMeasuring$.complete();
        this.delegate.distanceMeasuringStopped(this);
    }

    follow(): void {
        if (this.vessel == null) return;

        this.stopMeasuring();
        this.popup?.remove();
        this.popup = undefined;
        this.control = DistanceOverlayComponent.create(this.applicationRef, this.viewContainerRef, this.injector, this.vessel, this);
        this.mapBox.addControl(this.control, "bottom-right");
        this.redraw();
    }

    dispose(): void {
        if (this.disposed) return;

        this.stopMeasuring();
        this.disposed$.next();
        this.disposed$.complete();
        this.disposed = true;
        if (this.popup != null)
            this.popup.remove();
        if (this.control != null)
            this.mapBox.removeControl(this.control);
        this.delegate.removeDistanceMeasurer(this);
    }


    setHighlighted(highlighted: boolean): void {
        if (highlighted !== this.highlighted) {
            this.highlighted = highlighted;
            this.delegate.updateDistanceLayer();
        }
    }
}

interface DistanceMeasurerDelegate {
    distanceMeasuringStopped(measurer: DistanceMeasurer): void;

    removeDistanceMeasurer(measurer: DistanceMeasurer): void;

    updateDistanceLayer(): void;
}

export function formatEta(etaMinutes: Minutes): string {
    const relative = formatMinutes(etaMinutes, false, "min");
    const absolute = formatTime(currentLocalDateTimeMinutes().plusMinutes(etaMinutes));

    return `${relative} (${absolute})`;
}
