import { Duration, Instant, Knots, MMSI, pilotageStateCode } from "common";
import { LngLat } from "mapbox-gl";
import { computeDestinationPoint } from "geolib";
import { knotsToMS } from "./conversions";
import { NavigationStatus, navigationStatusInPlace } from "./ais";
import { PilotageInfo, VesselLocationEvent, VesselMetadataEvent } from "./types";

export const SHIP_TYPE_PILOT_VESSEL = 50;
export const MMSI_MID_FINLAND = 230;

const LOCATION_EVENT_VALIDITY_EXPIRING = Duration.ofMinutes(5);

/** If the vessel is going faster than this, we assume that it's moving even if navigation status tells otherwise */
const NAVIGATION_STATUS_SPEED_OVERRIDE_KNOTS = 5;

/**
 * Data needed to render vessels on a map.
 */
export class VesselData {

    /** Current location of the vessel. Initially bogus, but it doesn't matter because the vessel hasn't been added to map. */
    private _location = new LngLat(0, 0);

    /** Direction (in degrees) to which the vessel is pointing. */
    heading = 0;

    private status = NavigationStatus.NOT_DEFINED;

    /** Direction (in degrees) to which the vessel is moving. */
    private course: number | null = null;

    /** Speed of vessel in knots */
    private _speed: Knots | null = null;

    metadata: VesselMetadataEvent | null = null;
    pilotage: PilotageInfo | null = null;
    private locationEventTimestamp: Instant | null = null;

    get location(): LngLat {
        return this._location;
    }

    get speed(): Knots | null {
        return this._speed;
    }

    /** `null` if considered fresh */
    get ageOfExpiration(): Duration | null {
        if (this.locationEventTimestamp == null) // We don't know any event updates yet
            return null;

        const now = Instant.now();
        const age = Duration.between(this.locationEventTimestamp, now);
        return age.compareTo(LOCATION_EVENT_VALIDITY_EXPIRING) >= 0 ? age : null;
    }

    constructor(readonly mmsi: MMSI) {
    }

    get title(): string {
        return this.pilotage?.vesselName ?? this.metadata?.name ?? this.mmsi.toString();
    }

    get displayedTitle(): string {
        const name = this.metadata?.name ?? this.mmsi.toString();
        const speed = this.displayedSpeed;

        return (speed != null) ? `${name}\n${speed} kn` : name;
    }

    updateLocation(event: VesselLocationEvent): void {
        this.locationEventTimestamp = event.timestamp;

        function takeDirectionIfValid(direction: number | null): number | null {
            return direction !== 511 ? direction : null;
        }

        const heading = takeDirectionIfValid(event.heading) ?? takeDirectionIfValid(event.cog) ?? 0;
        const cog = takeDirectionIfValid(event.cog) ?? takeDirectionIfValid(event.heading) ?? 0;

        this._location = new LngLat(event.point.lng, event.point.lat);
        this._speed = event.sog as Knots;
        this.status = event.navStat;
        this.course = cog;
        this.heading = heading;
    }

    updateMetadata(metadata: VesselMetadataEvent): void {
        this.metadata = metadata;
    }

    private get displayedSpeed(): Knots | null {
        const speed = this._speed;

        const statusMoving = !navigationStatusInPlace(this.status);
        if (statusMoving || (speed != null && speed >= NAVIGATION_STATUS_SPEED_OVERRIDE_KNOTS))
            return speed;
        else
            return null;
    }

    get isPilotageRelated(): boolean {
        return this.pilotage != null || this.isFinnishPilotBoat;
    }

    get isFinnishPilotBoat(): boolean {
        return this.metadata != null && this.metadata.shipType === SHIP_TYPE_PILOT_VESSEL && mmsiMid(this.mmsi) === MMSI_MID_FINLAND;
    }

    get iconImage(): string {
        const baseName = this.isFinnishPilotBoat ? 'vessel-pilot-boat'
            : this.pilotage == null ? 'vessel-no-pilotage'
                : `vessel-pilotage-${pilotageStateCode(this.pilotage.state, this.pilotage.inVesselNoticeState)}`;

        return this.ageOfExpiration != null ? `${baseName}-dashed` : baseName;
    }

    isExpired(cutoff: Instant): boolean {
        return this.pilotage == null && (this.locationEventTimestamp == null || this.locationEventTimestamp.isBefore(cutoff));
    }

    get hasLocation(): boolean {
        return this.location.lat !== 0 && this.location.lng !== 0;
    }

    destination(projectedCourseMinutes: number): LngLat | null {
        const speed = this.displayedSpeed;
        if (this.course != null && speed != null && projectedCourseMinutes !== 0) {
            const target = computeDestinationPoint(this._location, knotsToMS(speed) * projectedCourseMinutes * 60, this.course);
            return new LngLat(target.longitude, target.latitude);
        } else
            return null;
    }
}

export function mmsiMid(mmsi: MMSI): number {
    return Math.floor(mmsi / 1000000);
}
