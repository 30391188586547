<mat-icon class="cursor-pointer float-right select-none"
          [ngClass]="expanded() ? '' : 'p-2 min-w-[40px]'"
          (click)="toggle()">
    {{ expanded() ? 'expand_less' : 'expand_more' }}
</mat-icon>

<div class="transition-all" [ngClass]="expanded() ? 'w-[200px] min-h-[100px] h-auto' : 'w-0 h-0'">
    <div [ngClass]="expanded() ? 'flex flex-col' : 'hidden'">
        <mat-checkbox [formControl]="pilotagesOnly">Pilotages only</mat-checkbox>
        <mat-checkbox [formControl]="chartSymbols">Chart symbols</mat-checkbox>

        <mat-form-field appearance="outline" class="mt-2 w-full">
            <mat-label>Projected course</mat-label>
            <mat-select [formControl]="projectedCourseMinutes">
                <mat-option [value]="0">None</mat-option>
                <mat-option [value]="15">15 min</mat-option>
                <mat-option [value]="30">30 min</mat-option>
                <mat-option [value]="60">60 min</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="mt-2 w-full">
            <mat-label>Search for vessel</mat-label>
            <input #vesselSearchField matInput [formControl]="vesselSearch" [matAutocomplete]="vesselAutoComplete">
            <mat-autocomplete #vesselAutoComplete="matAutocomplete" [autoActiveFirstOption]="true" (optionSelected)="searchForVessel($event)">
                @for (vessel of filteredVessels(); track $index) {
                    <mat-option [value]="vessel">{{ vessel.title }}</mat-option>
                }
            </mat-autocomplete>
        </mat-form-field>
    </div>
</div>
