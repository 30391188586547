export enum NavigationStatus {
    UNDER_WAY_USING_ENGINE = 0,
    AT_ANCHOR = 1,
    NOT_UNDER_COMMAND = 2,
    RESTRICTED_MANOEUVERABILITY = 3,
    CONSTRAINED_BY_HER_DRAUGHT = 4,
    MOORED = 5,
    AGROUND = 6,
    ENGAGED_IN_FISHING = 7,
    UNDER_WAY_SAILING = 8,
    RESERVED_9 = 9,
    RESERVED_10 = 10,
    RESERVED_11 = 11,
    RESERVED_12 = 12,
    RESERVED_13 = 13,
    AIS_SART_IS_ACTIVE = 14,
    NOT_DEFINED = 15
}

export function navigationStatusInPlace(status: NavigationStatus): boolean {
    return status === NavigationStatus.AT_ANCHOR
        || status === NavigationStatus.MOORED
        || status === NavigationStatus.AGROUND;
}
