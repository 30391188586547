import { ApplicationRef, ComponentRef } from "@angular/core";
import { IControl, Map } from "mapbox-gl";

/**
 * A MapBox Control that encapsulates an Angular component.
 */
export class MapboxAngularControl<T> implements IControl {

    private element?: HTMLElement;

    constructor(
        private readonly componentRef: ComponentRef<T>,
        private readonly applicationRef: ApplicationRef
    ) {
    }

    get componentInstance(): T {
        return this.componentRef.instance;
    }

    onAdd(_map: Map): HTMLElement {
        this.componentRef.changeDetectorRef.markForCheck();

        // Wrap the Angular component into a div that stops unhandled
        // events from propagating to the map.
        const div = document.createElement("div");
        div.classList.add("mapboxgl-ctrl");
        div.onmousedown = div.onclick = div.ondblclick = e => e.stopPropagation();
        div.appendChild(this.componentRef.location.nativeElement);
        this.element = div;
        return div;
    }

    onRemove(_map: Map): void {
        this.applicationRef.detachView(this.componentRef.hostView);
        this.componentRef.destroy();
        this.element?.remove();
    }
}
