import { ApplicationRef, ChangeDetectionStrategy, Component, Inject, InjectionToken, Injector, Signal, ViewContainerRef } from "@angular/core";
import { MapboxAngularPopup } from "../mapbox/mapbox-angular-popup";
import { Map } from "mapbox-gl";
import { CameraFeature } from "../geojson-mappings";
import { HelsinkiDatePipe, Instant } from "common";
import { MapDataProvider } from "../map-data-provider.service";
import { map } from "rxjs/operators";
import { toSignal } from "@angular/core/rxjs-interop";

const CAMERA_POPUP_DATA_KEY = new InjectionToken("CAMERA_POPUP_DATA_KEY");

@Component({
    templateUrl: './camera-popup.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        HelsinkiDatePipe,
    ]
})
export class CameraPopupComponent {

    readonly cameras: Signal<RenderedCameraInfo[] | undefined>;

    constructor(@Inject(CAMERA_POPUP_DATA_KEY) cameras: CameraFeature[],
                mapDataProvider: MapDataProvider) {

        this.cameras = toSignal(mapDataProvider.cameras$.pipe(map(allCameras => cameras.map(camera => {
            const cameraId = camera.properties.id;
            const latestData = allCameras.find(it => it.id === cameraId);
            return ({
                name: camera.properties.name,
                imageUrl: mapDataProvider.cameraUrl(cameraId, null),
                imageUrlWithTimestamp: mapDataProvider.cameraUrl(cameraId, latestData?.lastUpdated ?? Instant.now()),
                lastUpdated: latestData?.lastUpdated
            });
        }))));
    }

    static create(mapBox: Map, applicationRef: ApplicationRef, viewContainerRef: ViewContainerRef, parentInjector: Injector, cameras: CameraFeature[]): MapboxAngularPopup<CameraPopupComponent> {
        const injector = Injector.create({
            providers: [
                {provide: CAMERA_POPUP_DATA_KEY, useValue: cameras},
            ], parent: parentInjector
        });

        return new MapboxAngularPopup(mapBox, applicationRef, viewContainerRef.createComponent(CameraPopupComponent, {injector}), {
            maxWidth: "min(90vw, 600px)",
            anchor: "top"
        });
    }
}

interface RenderedCameraInfo {
    name: string;
    imageUrl: string;
    imageUrlWithTimestamp: string;
    lastUpdated: Instant | null | undefined;
}
