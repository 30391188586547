@if (viewData(); as vd) {
    <div>{{ vd.vesselName }}</div>
    <div>
        <span>{{ vd.distance | number }} nm</span>

        @if (vd.eta != null) {
            <span>, <span class="whitespace-nowrap">{{ vd.eta }}</span>
                @if (vd.speed != null) {
                    <span class="whitespace-nowrap"> at {{ vd.speed }} kn</span>
                }
            </span>
        }
    </div>

    @if (vd.vesselName != null) {
        <a (click)="follow($event)" class="cursor-pointer">Follow</a>
    }
}

