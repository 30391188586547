@if (viewData(); as vd) {
    <mat-icon class="float-right text-black text-[12px] w-[10px] cursor-pointer" (click)="close($event)">close</mat-icon>

    <div>{{ vd.vesselName }}</div>
    <div>
        <span>{{ vd.distance | number }} nm</span>
        @if (vd.eta != null) {
            <span>, <span class="whitespace-nowrap">{{ vd.eta }}</span>
                @if (vd.speed != null) {
                    <span class="whitespace-nowrap"> at {{ vd.speed }} kn</span>
                }
            </span>
        }
    </div>
}
