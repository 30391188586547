import { EventData, Map, MapEventType } from "mapbox-gl";
import { Observable } from "rxjs";

export function observeMapBoxEvent<T extends keyof MapEventType>(mapBox: Map, event: T): Observable<MapEventType[T] & EventData> {
    return new Observable(subscriber => {
        const listener = (e: MapEventType[T] & EventData): void => {
            subscriber.next(e);
        };

        mapBox.on(event, listener);

        return () => {
            mapBox.off(event, listener);
        };
    });
}
