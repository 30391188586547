@for (camera of cameras(); track $index) {
    <div>
        <div class="flex justify-between">
            <div class="name">{{ camera.name }}</div>
            <div class="last-updated">{{ camera.lastUpdated | helsinkiDate:"dd.MM.yyyy HH:mm:ss" }}</div>
        </div>

        <div class="image-wrapper">
            <a [href]="camera.imageUrl" target="_blank"><img [src]="camera.imageUrlWithTimestamp" [alt]="camera.name" class="aspect-video"></a>
        </div>
    </div>
}
