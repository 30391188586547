import { ApplicationRef, ChangeDetectionStrategy, Component, computed, ElementRef, Inject, InjectionToken, Injector, signal, Signal, ViewChild, ViewContainerRef } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { combineLatest, merge } from "rxjs";
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { VesselData } from "../vessel-data";
import { map, startWith } from "rxjs/operators";
import { MapboxAngularControl } from "../mapbox/mapbox-angular-control";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { NgClass } from "@angular/common";

const MAP_OPTIONS_DELEGATE_KEY = new InjectionToken("MAP_OPTIONS_DELEGATE_KEY");

export const defaultMapOptions: MapOptions = {
    pilotagesOnly: false,
    chartSymbols: false,
    projectedCourseMinutes: 30
};

@Component({
    templateUrl: './map-options.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatAutocompleteModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        NgClass,
    ],
    host: {
        '(mouseclick)': 'onMouseClick($event)',
        '[class]': 'classes()',
    }
})
export class MapOptionsComponent {

    readonly pilotagesOnly = new FormControl(defaultMapOptions.pilotagesOnly, {nonNullable: true});
    readonly chartSymbols = new FormControl(defaultMapOptions.chartSymbols, {nonNullable: true});
    readonly projectedCourseMinutes = new FormControl(defaultMapOptions.projectedCourseMinutes, {nonNullable: true});
    readonly vesselSearch = new FormControl<string | unknown>("", {nonNullable: true});
    readonly filteredVessels: Signal<readonly VesselData[]>;

    readonly expanded = signal(false);

    readonly classes = computed(() => {
        return ["mat-typography", "mat-elevation-z4", "block", "bg-white", "rounded", "min-h-[40px]",
            this.expanded() ? "p-2" : "p-0"];
    });

    @ViewChild('vesselSearchField') vesselSearchField?: ElementRef<HTMLElement>;

    constructor(@Inject(MAP_OPTIONS_DELEGATE_KEY) private readonly delegate: MapOptionsDelegate) {

        const pilotagesOnly$ = this.pilotagesOnly.valueChanges.pipe(startWith(this.pilotagesOnly.value as boolean));
        this.filteredVessels = toSignal(combineLatest([this.vesselSearch.valueChanges, pilotagesOnly$]).pipe(map(([query, pilotagesOnly]) =>
            (typeof query === "string") ? delegate.findVessels(query, pilotagesOnly) : [])), {initialValue: []});

        this.pilotagesOnly.valueChanges.subscribe(() => this.refreshState());

        merge(this.chartSymbols.valueChanges, this.pilotagesOnly.valueChanges, this.projectedCourseMinutes.valueChanges)
            .pipe(takeUntilDestroyed())
            .subscribe(() => this.refreshState());
    }

    onMouseClick(event: MouseEvent): void {
        event.stopPropagation();
        // When we are not expanded, clicking anywhere on the component expands.
        this.expanded.set(true);
    }

    toggle(): void {
        this.expanded.set(!this.expanded());

        if (!this.expanded()) {
            // On some mobile browsers, hiding the element won't blur the search field
            // automatically and the software keyboard stays on screen.
            this.vesselSearchField?.nativeElement?.blur();
        }
    }

    private refreshState(): void {
        this.delegate.optionsChanged({
            chartSymbols: this.chartSymbols.value,
            pilotagesOnly: this.pilotagesOnly.value,
            projectedCourseMinutes: this.projectedCourseMinutes.value
        });
    }

    searchForVessel(event: MatAutocompleteSelectedEvent): void {
        const vessel: VesselData = event.option.value;
        this.vesselSearch.setValue('');
        this.delegate.vesselSelected(vessel);
    }

    static create(applicationRef: ApplicationRef, viewContainerRef: ViewContainerRef, parentInjector: Injector, delegate: MapOptionsDelegate): MapboxAngularControl<MapOptionsComponent> {
        const injector = Injector.create({
            providers: [
                {provide: MAP_OPTIONS_DELEGATE_KEY, useValue: delegate}
            ],
            parent: parentInjector,
        });
        return new MapboxAngularControl(
            viewContainerRef.createComponent(MapOptionsComponent, {injector}),
            applicationRef,
        );
    }
}

export interface MapOptions {
    readonly chartSymbols: boolean;
    readonly pilotagesOnly: boolean;
    readonly projectedCourseMinutes: number;
}

export interface MapOptionsDelegate {
    optionsChanged(options: MapOptions): void;

    vesselSelected(vessel: VesselData): void;

    findVessels(query: string, pilotagesOnly: boolean): VesselData[];
}

