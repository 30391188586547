import { ApplicationRef, ChangeDetectionStrategy, Component, Inject, InjectionToken, Injector, ViewContainerRef } from "@angular/core";
import { PilotageInfo, VesselMetadataEvent } from "../types";
import { VesselData } from "../vessel-data";
import { MapboxAngularPopup } from "../mapbox/mapbox-angular-popup";
import { Map } from "mapbox-gl";
import { HelsinkiDatePipe, PilotageStateNamePipe } from "common";

const VESSEL_POPUP_DATA_KEY = new InjectionToken("VESSEL_POPUP_DATA_KEY");
const VESSEL_POPUP_DELEGATE_KEY = new InjectionToken("VESSEL_POPUP_DELEGATE_KEY");

@Component({
    templateUrl: './vessel-popup.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        PilotageStateNamePipe,
        HelsinkiDatePipe,
    ],
})
export class VesselPopupComponent {

    constructor(@Inject(VESSEL_POPUP_DATA_KEY) private readonly vesselData: VesselData,
                @Inject(VESSEL_POPUP_DELEGATE_KEY) private readonly delegate: VesselPopupDelegate) {
    }

    get title(): string {
        return this.vesselData.pilotage?.vesselName ?? this.vesselData.metadata?.name ?? this.vesselData.mmsi.toString();
    }

    get mmsi(): number {
        return this.vesselData.mmsi;
    }

    get pilotage(): PilotageInfo | null {
        return this.vesselData.pilotage;
    }

    get metadata(): VesselMetadataEvent | null {
        return this.vesselData.metadata;
    }

    get expirationInfo(): string | null {
        const elapsed = this.vesselData.ageOfExpiration;
        if (elapsed != null)
            return `Data is ${elapsed.toMinutes()} minutes old`;
        else
            return null;
    }

    startDistanceMeasurement(event: MouseEvent): void {
        event.preventDefault();

        this.delegate.startDistanceMeasurement(this.vesselData);
    }

    static create(mapBox: Map, applicationRef: ApplicationRef, viewContainer: ViewContainerRef, parentInjector: Injector, vessel: VesselData, delegate: VesselPopupDelegate): MapboxAngularPopup<VesselPopupComponent> {
        const injector = Injector.create({
            providers: [
                {provide: VESSEL_POPUP_DATA_KEY, useValue: vessel},
                {provide: VESSEL_POPUP_DELEGATE_KEY, useValue: delegate},
            ], parent: parentInjector
        });

        return new MapboxAngularPopup(mapBox, applicationRef, viewContainer.createComponent(VesselPopupComponent, {injector}));
    }
}

export interface VesselPopupDelegate {
    startDistanceMeasurement(vessel: VesselData): void;
}
